<template>
    <div class="landing">
        <Header />
        <DemoSection />
        <Footer />
    </div>
</template>

<script>

import Header from "./static/sections/Header.vue"
import DemoSection from "./static/demo-sections/DemoSection.vue"
import Footer from "./static/sections/Footer.vue"

export default {
    name: 'about',
    components: {
        Header,
        DemoSection,
        Footer
    },
}
</script>

<style lang="scss" scoped>

</style>
