<template>
    <main class="demo-section">
        <div class="demo__title white--text">
            <h2 class="demo__title-h2">
                {{ $t('general.convert-visits-into-sales') }}
            </h2>
            <p class="demo__title-p">
                {{ $t('general.implements-the-shopping-cart-widget') }}
            </p>
            <main class="demo-button">
                <div class="demo-button__btn-wrap">
                    <router-link class="demo-button__btn white--text" v-if="!$auth.user()" :to="{ name: 'sign-up' }">
                        {{ $t('general.get-started-for-free') }}
                    </router-link>
                    <router-link class="demo-button__btn white--text" v-if="$auth.user()" :to="{ name: 'dashboard.index' }">
                        {{ $t('general.dashboard') }}
                    </router-link>
                </div>
            </main>
        </div>
        <div class="demo-section__wrap-online-shop">
            <online-shop/>
        </div>
    </main>
</template>

<script>
import onlineShop from '@/components/OnlineShop.vue'

export default {
    components: {onlineShop}
}
</script>

<style lang="scss" scoped>
@import "../../../scss/mixins";

.demo-section {
    background: url(/img/main_background.jpg) center center no-repeat no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    &__wrap-online-shop {
        width: 100%;
        max-width: 1200px;
        display: block;
        position: relative;
        z-index: 10;
        visibility: visible;
        margin-bottom: 30px;
        border-radius: 20px;
        text-align: center;

        @media (min-width: 768px) {
            max-width: calc(100% - 60px);
        }

        @include lg-block(){
            max-width: calc(100% - 60px);
        }

        @include sm-block(){
            min-width: 350px;
            max-width: calc(100% - 30px);
            margin-bottom: 50px;
        }

        &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 10px #4474ee;
            background: #4474ee;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 4px;
        }
    }
}

.demo__title {
    min-width: 100%;
    text-align: center;

    h2.demo__title-h2 {
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        max-width: 520px;
        padding: 5px;
        margin: 40px auto 8px auto;

        @include lg-block(){
            font-size: 24px
        }
    }

    p.demo__title-p {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        max-width: 615px;
        padding: 5px;
        margin: 8px auto 24px auto;

        @include lg-block(){
            padding: 16px
        }

    }
}

.demo-button {

    &__title {
        margin-top: 30px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        min-width: 100%;

        @include lg-block() {
            font-size: 24px;
            margin: 20px auto;
        }
    }

    .demo-button__btn-wrap {
        min-width: 100%;
        text-align: center;
        margin-top: 24px;

        .demo-button__btn {
            font-size: 24px;
            width: max-content;
            padding: 20px 80px;
            border-radius: 10px;
            display: block;
            text-align: center;
            margin: 0 auto 40px;
            background: #1e5cba;

            @include sm-block() {
                padding: 10px 40px;
            }

            @include lg-block() {
                font-size: 20px;
            }

            &:hover {
                background: #154286;
                transition: all .3s;
            }
        }
    }

}
</style>
