<template>
    <iframe :src="url +'/homepage-widget.html'" class="iframe"></iframe>
</template>

<script>
export default {
    computed: {
        url() {
            return process.env.VUE_APP_APP_URL
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/mixins";

iframe.iframe {
    width: 1200px;
    min-width: 500px;
    max-width: 1200px;
    height: 580px;
    border: none;
    outline: none;
    border-radius: 5px;

    @include lg-block() {
        max-width: calc(100% - 60px);
        text-align: center;
    }

    @include sm-block() {
        min-width: 300px;
        max-width: calc(100% - 30px);
        text-align: center;
    }

    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 10px #4474ee;
        background: #4474ee;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 4px;
    }

    body {
        &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 10px #4474ee;
            background: #4474ee;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 4px;
        }
    }
}
</style>
